import { BulkReorderSupplierStyleDetail } from "@/gql/graphql";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
interface StylesStateType {
  items: BulkReorderSupplierStyleDetail[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error?: string;
}

const initialState: StylesStateType = {
  items: [],
  loading: "idle",
  error: "",
};

// First, create the thunk
export const fetchStyles = createAsyncThunk("styles/fetchStyles", async () => {
  return [];
});

export const counterSlice = createSlice({
  name: "styles",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<BulkReorderSupplierStyleDetail[]>) => {
      state.items = action.payload;
      state.loading = "idle";
    },
    update: (
      state,
      action: PayloadAction<{
        index: number;
        item: BulkReorderSupplierStyleDetail;
      }>
    ) => {
      state.items[action.payload.index] = action.payload.item;
      state.loading = "idle";
    },
    delete: (
      state,
      action: PayloadAction<{
        index: number;
        item: BulkReorderSupplierStyleDetail;
      }>
    ) => {
      state.items[action.payload.index] = action.payload.item;
      state.loading = "idle";
    },
    error: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = "idle";
    },
    loading: (state, action: PayloadAction<StylesStateType["loading"]>) => {
      state.loading = action.payload || "idle";
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchStyles.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchStyles.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchStyles.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.items = action.payload || [];
      });
  },
});

export default counterSlice.reducer;
