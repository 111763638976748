import React from "react";
import { Provider } from "react-redux";
import { store } from "./src/store";
import { ConfigProvider } from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import dayjs from "dayjs";
dayjs.locale("en");

const App = ({ element }) => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={enUSIntl}>{element}</ConfigProvider>
    </Provider>
  );
};

export default App;
