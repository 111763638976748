import { combineReducers } from "redux";
import counterSlice from "./counterSlice";
import stylesSlice from "./stylesSlice";

const rootReducer = combineReducers({
  counter: counterSlice,
  styles: stylesSlice,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
